export const dash = "—";
export const oneHourSeconds = 3600;
import { Duration } from "luxon";
export default {
  round(value, precision) {
    if (value) {
      const multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    }
    return value;
  },

  toHoursMinutesDuration(durationInSeconds) {
    let hours = Math.floor(durationInSeconds / 3600);
    let remainingSeconds = durationInSeconds % 3600;
    let minutes = Math.floor(remainingSeconds / 60);
    return {
      hours: hours,
      minutes: minutes,
    };
  },

  toHoursMinutesSecondsDuration(durationInSeconds) {
    let hours = Math.floor(durationInSeconds / 3600);
    let remainingSeconds = durationInSeconds % 3600;
    let minutes = Math.floor(remainingSeconds / 60);
    remainingSeconds = remainingSeconds % 60;
    return {
      hours: hours,
      minutes: minutes,
      seconds: remainingSeconds,
    };
  },

  sortByCreationTimeDescendingFunction(a, b) {
    if (a.creation_time > b.creation_time) return -1;
    if (a.creation_time < b.creation_time) return 1;
    return 0;
  },

  getRoundedPercentageOrDash(percentage, isAuthorized) {
    const authorized = isAuthorized === null || isAuthorized === undefined || isAuthorized === true;
    if (percentage !== null && percentage !== undefined && authorized) {
      let roundedPercentage = this.round(percentage, 1);
      if (Number.isNaN(roundedPercentage)) {
        return dash;
      } else {
        return roundedPercentage + "%";
      }
    } else {
      return dash;
    }
  },

  getRoundedValueOrDash(value, isAuthorized, precision = 1) {
    const authorized = isAuthorized === null || isAuthorized === undefined || isAuthorized === true;
    if (value !== null && value !== undefined && authorized) {
      let roundedValue = this.round(value, precision);
      if (Number.isNaN(roundedValue)) {
        return dash;
      } else {
        return roundedValue;
      }
    } else {
      return dash;
    }
  },

  getRoundedValueOrZeroOrDash(value, isAuthorized) {
    const authorized = isAuthorized === null || isAuthorized === undefined || isAuthorized === true;
    if (value !== null && value !== undefined && authorized) {
      let roundedValue = this.round(value, 1);
      if (Number.isNaN(roundedValue)) {
        return dash;
      } else {
        return roundedValue;
      }
    } else {
      return dash;
    }
  },

  getDurationTimeFormatOrZero(value, isAuthorized) {
    const authorized = isAuthorized === null || isAuthorized === undefined || isAuthorized === true;
    if (!authorized) return dash;
    if (value !== null && value !== undefined && value !== 0.0 && authorized) {
      if (Number.isNaN(value)) {
        return "00:00:00";
      } else {
        return Duration.fromMillis(value).toFormat("hh':'mm':'ss");
      }
    } else {
      return "00:00:00";
    }
  },

  isNotNullOrUndefined(value) {
    if (value !== null && value !== undefined) {
      return true;
    }
    return false;
  },
};
