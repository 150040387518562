import { DateTime } from "luxon";

export function compareProductionUnitsByName(pu1, pu2) {
  if (pu1.name < pu2.name) {
    return -1;
  }
  if (pu1.name > pu2.name) {
    return 1;
  }
  return 0;
}

export function sortAlertsByDurationDescending(alert1, alert2) {
  const duration1 = alert1.end_time - alert1.start_time;
  const duration2 = alert2.end_time - alert2.start_time;
  if (duration1 < duration2) {
    return 1;
  }
  if (duration1 > duration2) {
    return -1;
  }
  return 0;
}

export function sortCoverageByStartDateDesc(cov1, cov2) {
  const start1 = DateTime.fromISO(cov1.start_date).toMillis();
  const start2 = DateTime.fromISO(cov2.start_date).toMillis();
  return start1 > start2 ? -1 : start2 > start1 ? 1 : 0;
}

export function sortProductionUnitsByDisabledStatus(pu1, pu2) {
  // used in the production run form to have the active (disabled) production unit at the top
  if ((!pu1.disabled && !pu2.disabled) || (pu1.disabled && pu2.disabled)) return 0;
  if (pu1.disabled && !pu2.disabled) return -1;
  if (!pu1.disabled && pu2.disabled) return 1;
}
