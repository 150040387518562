/* eslint-disable no-unused-vars */
import http from "../../BaseServiceHttp";
import store from "../../store";
import { getTileByName, getTileName, tileKeyByName } from "@/components/Tiles";
import i18n from "@/i18n";
import { DIRECTOR_ROLE, PRESENTER_ROLE, SUPERVISOR_ROLE } from "@/store/modules/user";
import { validateAndTransformUserPreferences } from "@/components/user/UserPreferencesService";

class UserService {
  getUsers(factoryId) {
    return http.get(`/factories/${factoryId}/users`);
  }

  getUser(userId) {
    return http.get(`/users/${userId}`);
  }

  async getUserPreferences(authorizedProductionUnitIds) {
    let response = await http.get(`/user/preferences`);
    response.data = validateAndTransformUserPreferences(response.data, authorizedProductionUnitIds);
    return response;
  }

  createUser(newUser) {
    return http.post(`/users`, newUser);
  }

  updateUser(userId, userUpdateRequest) {
    return http.put(`/users/${userId}`, userUpdateRequest);
  }

  deleteUser(userId) {
    return http.delete(`/users/${userId}`);
  }

  getAccount(accountId) {
    return http.get(`/accounts/${accountId}`);
  }

  getAvatarText(displayName) {
    if (displayName && displayName.length > 0) {
      let parts = displayName
        .split(/(\s+)/)
        .map((p) => p.trim())
        .filter((p) => p.length > 0);

      if (parts.length === 1) {
        return parts[0].charAt(0).toUpperCase();
      }
      if (parts.length >= 2) {
        return parts
          .map((p) => p.charAt(0).toUpperCase())
          .slice(0, 2)
          .join("");
      }
    }
    return "";
  }

  getAvailableRoles() {
    return [
      { value: PRESENTER_ROLE, text: i18n.t("user.roles.presentation") },
      { value: SUPERVISOR_ROLE, text: i18n.t("user.roles.operation") },
      { value: DIRECTOR_ROLE, text: i18n.t("user.roles.management") },
    ];
  }

  getOperationRoleOption() {
    return { value: SUPERVISOR_ROLE, text: i18n.t("user.roles.operation") };
  }

  isWorximityUser(user) {
    return user.email.startsWith("wxadmin") || user.email.startsWith("wxsysadmin");
  }

  isPresentor(role) {
    return role === PRESENTER_ROLE;
  }

  isOperation(role) {
    return role === SUPERVISOR_ROLE;
  }

  isManagement(role) {
    return role === DIRECTOR_ROLE;
  }

  getDisplayRoleNameForValue(roleValue) {
    let availableRole = this.getAvailableRoles().find((r) => r.value === roleValue);
    return availableRole?.text ?? i18n.t("user.roles.invalid");
  }

  buildTileSelection(tiles) {
    return tiles.map((tileSlot) => getTileName(tileSlot.tile, tileSlot.config));
  }

  updatePreferences(newPreferences) {
    return http.put(`/user/preferences`, newPreferences);
  }

  updateActiveProductionUnitTileSelection(preferencesDB, tileIndex, tileName, tileConfig, authorizedPuIds) {
    const activeProductionUnitId = store.getters["dashboard/activeProductionUnitId"];
    const dashboardIndex = preferencesDB.dashboards.production_units.findIndex(
      (pu) => pu.id === activeProductionUnitId,
    );
    if (dashboardIndex < 0) return preferencesDB;
    const preferences = JSON.parse(JSON.stringify(preferencesDB));
    preferences.dashboards.production_units[dashboardIndex].tiles[tileIndex] = {
      tile: getTileByName(tileName),
      config: tileConfig,
    };
    return validateAndTransformUserPreferences(preferences, authorizedPuIds);
  }

  updateOverviewKpiSelection(preferencesDB, activeKpi, config, authorizedPuIds) {
    const preferences = JSON.parse(JSON.stringify(preferencesDB));
    preferences.overview.selected_kpi = getTileByName(tileKeyByName[activeKpi]);
    preferences.overview.scope = config?.scope ?? null;
    preferences.overview.product_unit = config?.product_unit ?? null;
    preferences.overview.time_unit = config?.time_unit ?? null;
    return validateAndTransformUserPreferences(preferences, authorizedPuIds);
  }

  updateActiveProductionUnitGraphSelection(newSelectedGraph, graphConfig) {
    const activeProductionUnitId = store.getters["dashboard/activeProductionUnitId"];
    let userPreferences = store.getters["user/preferences"];

    const productionUnitDashboardPreferences = userPreferences.dashboards.production_units.find(
      (pu) => pu.id === activeProductionUnitId,
    );
    if (productionUnitDashboardPreferences) {
      if (graphConfig) {
        productionUnitDashboardPreferences.graph = {
          selected_kpi: newSelectedGraph,
          config: graphConfig,
        };
      } else {
        productionUnitDashboardPreferences.graph = {
          selected_kpi: newSelectedGraph,
        };
      }
    }

    return userPreferences;
  }
}

export default new UserService();
