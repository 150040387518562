import helpers from "@/helpers";

export function toProductionUnitOverview(productionUnitMetrics, productionUnitsState, productionUnits) {
  const puMetrics = productionUnitMetrics.metrics;
  const productionUnitId = productionUnitMetrics.key.production_unit_id;
  const productionUnit = productionUnits.find((pu) => pu.id === productionUnitId);
  const stateForPU = productionUnitsState.find((state) => state.puId === productionUnitId);
  const currentSku = stateForPU.productSku ? stateForPU.productSku : "";

  if (puMetrics) {
    const allProductQuantityValue = helpers.round(puMetrics.produced_quantity.total_count, 0);
    const currentProductQuantityValue = helpers.round(
      puMetrics.produced_quantity.quantity_by_sku[currentSku]?.count,
      0,
    );
    const ratePerHourValue = helpers.round(
      puMetrics.produced_quantity.quantity_by_sku[currentSku]?.count_throughput,
      1,
    );
    const ratePerHourTargetValue = helpers.round(
      puMetrics.performance.performance_by_sku[currentSku]?.production_objective,
      1,
    );
    const wlvRatePerHourValue = helpers.round(
      puMetrics.weight_length_volume_quantity.quantity_by_sku[currentSku]?.weight_length_volume_throughput?.value,
      4,
    );
    const wlvRatePerHourTargetValue = helpers.round(
      puMetrics.weight_length_volume_quantity.quantity_by_sku[currentSku]?.weight_length_volume_throughput?.cadence_objective,
      4,
    );
    const wlvRateUnitValue = puMetrics.weight_length_volume_quantity.quantity_by_sku[currentSku]?.count_and_quantity.standard_unit;
    const ratePerMinuteValue = ratePerHourValue ? helpers.round(ratePerHourValue / 60.0, 1) : null;
    const ratePerMinuteTargetValue = helpers.round(
      puMetrics.performance.performance_by_sku[currentSku]?.production_objective / 60.0,
      1,
    );
    const wlvRatePerMinuteValue = wlvRatePerHourValue >= 0 ? helpers.round(wlvRatePerHourValue / 60.0, 4) : null;
    const wlvRatePerMinuteTargetValue = wlvRatePerHourTargetValue >= 0 ? helpers.round(wlvRatePerHourTargetValue / 60.0, 4) : null;
    const giveawayPercentageValue = helpers.round(puMetrics.giveaway.total_giveaway_percentage, 1);
    const giveawayPercentageTarget = puMetrics.giveaway.giveaway_by_sku[currentSku]?.configured_objective_percentage;
    const currentProductGiveawayQuantityInStandardUnit =
      puMetrics.giveaway.giveaway_by_sku[currentSku]?.giveaway_quantity;
    const currentProductGiveawayValue = helpers.round(
      puMetrics.giveaway.giveaway_by_sku[currentSku]?.giveaway_percentage,
      1,
    );
    const uptimeValue = helpers.round(puMetrics.availability.total_uptime, 1);
    const plannedDowntimeValue = helpers.round(puMetrics.time_distribution.total_planned_downtime, 1);
    const unplannedDowntimeValue = helpers.round(
      puMetrics.time_distribution.total_unplanned_downtime + puMetrics.time_distribution.total_unjustified_downtime,
      1,
    );
    const rejectQuantityValue = helpers.round(puMetrics.quality.total_reject_quantity, 1);
    const totalRejectedWeightValue = puMetrics.reject_quantity.total_weight;
    const totalRejectedLengthValue = puMetrics.reject_quantity.total_length;
    const totalRejectedVolumeValue = puMetrics.reject_quantity.total_volume;
    const netQuantityValue = helpers.round(puMetrics.quality.total_produced_quantity - (rejectQuantityValue || 0), 1);
    const wlvQuantity = puMetrics.weight_length_volume_quantity;

    const totalWeightValue = helpers.round(wlvQuantity.total_weight.total_value, 1);
    const totalWeightStandardUnit = wlvQuantity.total_weight.standard_unit;

    const totalLengthValue = helpers.round(wlvQuantity.total_length.total_value, 1);
    const totalLengthStandardUnit = wlvQuantity.total_length.standard_unit;

    const totalVolumeValue = helpers.round(wlvQuantity.total_volume.total_value, 1);
    const totalVolumeStandardUnit = wlvQuantity.total_volume.standard_unit;

    const averageWeightLengthVolumeValue = puMetrics.giveaway.giveaway_by_sku[currentSku]?.average_weight_length_volume;

    const averageWeightLengthVolumeTarget = helpers.round(
      puMetrics.giveaway.giveaway_by_sku[currentSku]?.product_target,
      1,
    );
    const averageWeightLengthVolumeType = puMetrics.giveaway.giveaway_by_sku[currentSku]?.measure_type;

    const wlvQuantityForSKU = wlvQuantity.quantity_by_sku[currentSku];
    const configuredUnit = wlvQuantityForSKU?.count_and_quantity?.configured_unit
      ? wlvQuantityForSKU?.count_and_quantity?.configured_unit
      : "count";
    const standardUnit = wlvQuantityForSKU?.count_and_quantity.standard_unit
      ? wlvQuantityForSKU?.count_and_quantity.standard_unit
      : "count";

    return {
      puId: productionUnitId,
      puName: productionUnit.name,
      puStatus: stateForPU.puStatus,
      productSku: currentSku,
      productName: stateForPU.productName,
      availabilityValue: helpers.round(puMetrics.availability.value, 1),
      availabilityTarget: productionUnit.availabilityTarget,
      performanceValue: helpers.round(puMetrics.performance.value, 1),
      performanceTarget: productionUnit.performanceTarget,
      qualityValue: helpers.round(puMetrics.quality.value, 1),
      qualityTarget: productionUnit.qualityTarget,
      oeeValue: helpers.round(puMetrics.oee, 1),
      oeeTarget: productionUnit.oeeTarget,
      ooeValue: helpers.round(puMetrics.ooe, 1),
      ooeTarget: productionUnit.ooeTarget,
      allProductQuantityValue: allProductQuantityValue,
      currentProductQuantityValue: currentProductQuantityValue,
      ratePerHourValue: ratePerHourValue,
      ratePerHourTargetValue: ratePerHourTargetValue,
      wlvRatePerHourValue: wlvRatePerHourValue,
      wlvRatePerHourTargetValue: wlvRatePerHourTargetValue,
      wlvRateUnit: wlvRateUnitValue,
      ratePerMinuteValue: ratePerMinuteValue,
      ratePerMinuteTargetValue: ratePerMinuteTargetValue,
      wlvRatePerMinuteValue: wlvRatePerMinuteValue,
      wlvRatePerMinuteTargetValue: wlvRatePerMinuteTargetValue,
      giveawayPercentageValue: giveawayPercentageValue,
      giveawayPercentageTarget: giveawayPercentageTarget,
      currentProductGiveawayQuantityInStandardUnit: currentProductGiveawayQuantityInStandardUnit,
      currentProductGiveawayValue: currentProductGiveawayValue,
      uptimeValue: uptimeValue,
      plannedDowntimeValue: plannedDowntimeValue,
      unplannedDowntimeValue: unplannedDowntimeValue,
      netQuantityValue: netQuantityValue,
      rejectQuantityValue: rejectQuantityValue,
      rejectedWeightValue: totalRejectedWeightValue,
      rejectedLengthValue: totalRejectedLengthValue,
      rejectedVolumeValue: totalRejectedVolumeValue,
      totalWeightValue: totalWeightValue,
      totalWeightStandardUnit: totalWeightStandardUnit,
      totalLengthValue: totalLengthValue,
      totalLengthStandardUnit: totalLengthStandardUnit,
      totalVolumeValue: totalVolumeValue,
      totalVolumeStandardUnit: totalVolumeStandardUnit,
      averageWeightLengthVolumeValue: averageWeightLengthVolumeValue,
      averageWeightLengthVolumeTarget: averageWeightLengthVolumeTarget,
      averageWeightLengthVolumeType: averageWeightLengthVolumeType,
      configuredUnit: configuredUnit,
      standardUnit: standardUnit,

      // Load these KPIs on selection
      timeToCompletionValue: null,
      completionPercentageValue: null,
      completionNetQuantityValue: null,
      completionPlannedQuantityValue: null,
    };
  } else {
    return {
      puId: productionUnitId,
      puName: productionUnit.name,
      puStatus: stateForPU.puStatus,
      productSku: currentSku,
      productName: stateForPU.productName,
      availabilityValue: 0,
      availabilityTarget: productionUnit.availabilityTarget,
      performanceValue: 0,
      performanceTarget: productionUnit.performanceTarget,
      qualityValue: 0,
      qualityTarget: productionUnit.qualityTarget,
      oeeValue: 0,
      oeeTarget: productionUnit.oeeTarget,
      ooeValue: 0,
      ooeTarget: productionUnit.ooeTarget,
      allProductQuantityValue: null,
      currentProductQuantityValue: null,
      ratePerHourValue: null,
      ratePerHourTargetValue: null,
      wlvRatePerHourValue: null,
      wlvRatePerHourTargetValue: null,
      wlvRateUnit: null,
      ratePerMinuteValue: null,
      ratePerMinuteTargetValue: null,
      wlvRatePerMinuteValue: null,
      wlvRatePerMinuteTargetValue: null,
      giveawayPercentageValue: null,
      giveawayPercentageTarget: null,
      currentProductGiveawayQuantityInStandardUnit: null,
      currentProductGiveawayValue: null,
      uptimeValue: null,
      plannedDowntimeValue: null,
      unplannedDowntimeValue: null,
      netQuantityValue: null,
      rejectQuantityValue: null,
      rejectedWeightValue: null,
      rejectedLengthValue: null,
      rejectedVolumeValue: null,
      timeToCompletionValue: null,
      completionPercentageValue: null,
      completionNetQuantityValue: null,
      completionPlannedQuantityValue: null,
      totalWeightValue: null,
      totalWeightStandardUnit: null,
      totalLengthValue: null,
      totalLengthStandardUnit: null,
      totalVolumeValue: null,
      totalVolumeStandardUnit: null,
      averageWeightLengthVolumeValue: null,
      averageWeightLengthVolumeTarget: null,
      averageWeightLengthVolumeType: null,
      configuredUnit: null,
      standardUnit: null,
    };
  }
}

export function extractLatestMetricsByShift(metricsByShiftForTimeCoverage, productionUnits) {
  const shiftMetricsForCurrentDayByProductionUnit = new Map();

  metricsByShiftForTimeCoverage.forEach((metric) =>
    addMetricIfMostRecent(metric, shiftMetricsForCurrentDayByProductionUnit),
  );

  addMissingProductionUnits(productionUnits, shiftMetricsForCurrentDayByProductionUnit);

  return Array.from(shiftMetricsForCurrentDayByProductionUnit.values());
}

function addMetricIfMostRecent(metric, shiftMetricsByProductionUnit) {
  const puId = metric.key.production_unit_id;
  const existing = shiftMetricsByProductionUnit.get(puId);

  if (!existing || metric.key.start_time > existing.key.start_time) {
    shiftMetricsByProductionUnit.set(puId, metric);
  }
}

function addMissingProductionUnits(productionUnits, shiftMetricsByProductionUnit) {
  productionUnits.forEach((pu) => {
    if (!shiftMetricsByProductionUnit.has(pu.id)) {
      shiftMetricsByProductionUnit.set(pu.id, { key: { production_unit_id: pu.id } });
    }
  });
}
