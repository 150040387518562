import TimelineService from "@/components/dashboard/TimelineService";
import DowntimeJustificationService from "@/components/dashboard/DowntimeJustificationService";
import ProductService from "@/components/product/ProductService";
import ProductionRunService from "@/components/dashboard/productionRun/ProductionRunService";
import TileService from "@/components/dashboard/tiles/TileService";
import ErrorHandling from "@/components/ErrorHandling";
import i18n from "@/i18n";
import GraphService from "@/components/dashboard/GraphService";
import Vue from "vue";
import UserService from "@/components/user/UserService";
import Tiles, { tileKeyByName } from "@/components/Tiles";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import Helpers from "@/helpers";
import * as TimeUtils from "@/store/TimeUtils";
import * as SortUtils from "@/components/SortUtils";
import RejectReasonService from "@/components/rejectreason/RejectReasonService";
import RejectService from "@/components/dashboard/RejectService";
import PackageFeatures from "@/components/PackageFeatures";
import convert from "convert-units";
import { isDurationGreaterThanNDays, getQuantityGraphTimeAggregation, PRODUCTION_RUN } from "@/store/TimeUtils";
import { DateTime } from "luxon";
import { getUnitType } from "@/components/user/UserPreferencesService";
import { convertKilogramTo, convertLiterTo, convertMeterTo } from "@/UnitUtils";

export default {
  namespaced: true,

  state: {
    activeProductionUnitId: "",

    tileSelection: [],
    giveawayViewToggle: false,
    samplingSessionInProgress: null,
    factoryProducts: [],
    startingSku: null,
    currentProduct: null,
    currentProductionRun: null,
    currentWorkOrder: null,
    currentLot: null,
    currentGiveawayProductSku: null,
    currentProductPUAssociations: [],
    cycleStats: [],
    timeline: {
      offsetFromMidnight: 0,
      coverageProgress: 0,
      coverageDuration: 0,
      blocks: [],
      markers: [],
    },
    oeeGraph: {
      coverageDuration: 0,
      interval: 0,
      offset: 0,
      target: 0,
      labels: [],
      curveData: [],
      oeeSources: [],
    },

    quantityGraph: {
      labels: [],
      quantityData: [],
    },

    giveawayGraph: null,
    currentDowntimeDuration: 0,
    selectedDowntimeId: null,
    availableDowntimeReasons: [],

    availableRejectReasons: [],

    activeProductionUnit: null,
    justificationDelayInSeconds: null,
    activeProductionUnitObjectives: [],
    activeProductionUnitTags: [],

    availability: null,
    performance: null,
    quality: null,
    oee: null,
    ooe: null,
    rejectQuantity: null,
    rejectQuantityByUnit: null,
    wlvRejectQuantity: {
      totalWeight: null,
      totalLength: null,
      totalVolume: null,
    },
    allProductsQuantity: null,
    allProductsQuantityByUnit: null,
    currentProductQuantity: null,
    currentProductQuantityByUnit: null,
    currentProductThroughput: null,
    currentProductThroughputByUnit: null,
    currentProductWLVThroughput: {
      standardUnit: null,
      value: null,
      target: null,
    },
    currentProductSpeed5m: null,
    currentProductSpeed5mByUnit: null,
    currentProductWLVSpeed5m: {
      standardUnit: null,
      value: null,
    },
    currentProductProductionObjective: null,
    currentProductProductionObjectiveByUnit: null,
    timeDistribution: null,
    wlvQuantity: null,
    giveaway: null,
    giveawaySku: null,
    productGiveaway: {
      value: null,
      valuePercent: null,
      target: null,
      targetPercent: null,
      unit: null,
    },
    averageProductGiveaway: {
      value: null,
      target: null,
      unit: null,
    },

    workShiftCoverage: null,
    productionRunCoverage: null,
    currentProductionRunMetrics: null,

    // is[placeholder]Loading controls whether a call is currently ongoing. This is so that if for whatever reason
    // a call hangs for a while, we don't start another one before it finishes.

    // is[placeholder]Loaded controls whether there is data to display. View will look at this to either show
    // a skeleton loader or the data. Should only be false on the initial page load and remain true afterwards
    // to avoid flashing cards.
    isMetricsLoading: false,
    isMetricsLoaded: false,
    isCurrentProductionRunLoading: false,
    isCurrentProductionRunLoaded: false,
    isTimelineLoading: false,
    isTimelineLoaded: false,
    isGraphLoading: false,
    isGraphLoaded: false,
    isGiveawayGraphLoading: false,
    isGiveawayGraphLoaded: false,
    productionUnitProducts: [],
  },

  getters: {
    // loaders
    isTilesLoaded(state) {
      return state.isMetricsLoaded || state.isCurrentProductionRunLoaded;
    },
    isPanelLoaded(state) {
      if (state.giveawayViewToggle) {
        return state.isGiveawayGraphLoaded;
      } else {
        return state.isTimelineLoaded;
      }
    },
    workShiftCoverage(state) {
      return state.workShiftCoverage;
    },
    productionRunCoverage(state) {
      return state.productionRunCoverage;
    },
    samplingSessionInProgress(state) {
      return state.samplingSessionInProgress;
    },
    giveawayViewToggle(state) {
      return state.giveawayViewToggle;
    },
    currentProductionRunMetrics(state) {
      return state.currentProductionRunMetrics;
    },
    activeProductionUnitId(state) {
      return state.activeProductionUnitId;
    },
    tileSelection(state) {
      return state.tileSelection;
    },
    productionUnitName(state, getters, rootState, rootGetters) {
      const productionUnits = rootGetters["navigation/activeFactoryProductionUnits"];
      const productionUnit = productionUnits.find((item) => item.id === state.activeProductionUnitId);
      return productionUnit?.name;
    },
    productionUnitConvertedUnit(state, getters, rootState, rootGetters) {
      const productionUnits = rootGetters["navigation/activeFactoryProductionUnits"];
      const productionUnit = productionUnits.find((item) => item.id === state.activeProductionUnitId);
      return productionUnit?.convertedUnitName;
    },
    productionUnitIsNetQuantityEnabled(state, getters, rootState, rootGetters) {
      const productionUnits = rootGetters["navigation/activeFactoryProductionUnits"];
      const productionUnit = productionUnits.find((item) => item.id === state.activeProductionUnitId);
      return productionUnit?.isNetQuantityEnabled;
    },
    productionUnitProducts(state) {
      return state.productionUnitProducts;
    },
    currentTimelineBlock(state) {
      if (state.timeline && state.timeline.blocks.length > 0) {
        return state.timeline.blocks[state.timeline.blocks.length - 1];
      }
    },
    currentTimelineBlockState(state, getters) {
      if (getters.currentTimelineBlock) {
        // if mixed-reasons downtime, use last
        if (getters.currentTimelineBlock.sub_blocks && getters.currentTimelineBlock.sub_blocks.length > 0) {
          return getters.currentTimelineBlock.sub_blocks[getters.currentTimelineBlock.sub_blocks.length - 1].state;
        } else return getters.currentTimelineBlock.state;
      }
    },
    currentProductionUnitDataSourceAlerts(state, getters, rootState, rootGetters) {
      const factoryAlerts = rootGetters["navigation/activeFactoryDataSourceAlerts"];
      return factoryAlerts.filter((a) => a.production_unit_ids.includes(state.activeProductionUnitId));
    },
    currentProductionUnitState(state, getters, rootState, rootGetters) {
      const factoryAlerts = rootGetters["navigation/activeFactoryDataSourceAlerts"];
      const alertsForActivePU = factoryAlerts.filter((a) =>
        a.production_unit_ids.includes(state.activeProductionUnitId),
      );

      if (alertsForActivePU && alertsForActivePU.length > 0) {
        return "connection_issues";
      } else {
        switch (getters.currentTimelineBlockState) {
          case "unknown":
          case "out_of_production":
          case "down_unjustified":
          case "down_unplanned":
          case "down_planned":
          case "up":
            return getters.currentTimelineBlockState;
          default:
            return "unknown";
        }
      }
    },
    currentProduct(state) {
      return state.currentProduct;
    },
    currentProductionRun(state) {
      return state.currentProductionRun;
    },
    currentWorkOrder(state) {
      return state.currentWorkOrder;
    },
    currentLot(state) {
      return state.currentLot;
    },
    cycleStats(state) {
      return state.cycleStats;
    },
    currentProductSku(state) {
      return state.currentProduct ? state.currentProduct.sku : null;
    },
    currentProductPUAssociations(state) {
      return state.currentProductPUAssociations;
    },
    currentProductProductionObjective: (state) => (unit) => {
      if (!unit) return state.currentProductProductionObjective;
      const unitType = getUnitType(unit);
      switch (unitType) {
        case "product_unit": {
          return state.currentProductProductionObjectiveByUnit
            ? state.currentProductProductionObjectiveByUnit[unit]
            : null;
        }
        case "weight_unit": {
          // throughput was asked to be shown in weight but the current wlv throughput is not in weight
          if (state.currentProductWLVThroughput.standardUnit !== "kg") return null;
          return convertKilogramTo(state.currentProductWLVThroughput.target, unit);
        }
        case "length_unit": {
          // throughput was asked to be shown in length but the current wlv throughput is not in length
          if (state.currentProductWLVThroughput.standardUnit !== "m") return null;
          return convertMeterTo(state.currentProductWLVThroughput.target, unit);
        }
        case "volume_unit": {
          // throughput was asked to be shown in volume but the current wlv throughput is not in volume
          if (state.currentProductWLVThroughput.standardUnit !== "l") return null;
          return convertLiterTo(state.currentProductWLVThroughput.target, unit);
        }
        default:
          return null;
      }
    },
    timeline(state) {
      return state.timeline;
    },
    oeeGraph(state) {
      return state.oeeGraph;
    },
    quantityGraph(state) {
      return state.quantityGraph;
    },
    giveawayGraph(state) {
      return state.giveawayGraph;
    },
    currentDowntime(state, getters, rootState, rootGetters) {
      return rootGetters["navigation/isLiveData"] ? getters.currentTimelineBlock?.downtime : null;
    },
    currentDowntimeDuration(state) {
      return state.currentDowntimeDuration;
    },
    unjustifiedDowntimes(state, getters) {
      return state.timeline.blocks
        .filter((block) => block.state === "down_unjustified")
        .map((block) => block.downtime)
        .filter((downtime) => downtime.end_time - downtime.start_time >= getters.activePUJustificationDelayInMillis);
    },
    partiallyJustifiedDowntimes(state, getters) {
      return state.timeline.blocks
        .filter((block) => block.state === "down_mixed")
        .map((block) => block.downtime)
        .filter((downtime) => {
          const toJustifyBlocks = downtime.justifications
            .filter((j) => j.reason == null)
            .filter((j) => {
              const effectiveEndTime = j.end_time ? j.end_time : downtime.end_time;
              return effectiveEndTime - j.start_time >= getters.activePUJustificationDelayInMillis;
            });
          return toJustifyBlocks.length > 0;
        });
    },
    unjustifiedDowntimesBeforeSelected(state, getters) {
      const justifiableDowntimes = getters.unjustifiedDowntimes.filter(
        (d) => d.end_time - d.start_time >= getters.activePUJustificationDelayInMillis,
      );
      return justifiableDowntimes.filter((d) => d.start_time < getters.selectedDowntime.start_time);
    },
    downtimes(state, getters) {
      return state.timeline.blocks
        .filter((block) => block.downtime && !getters.isBlockOverlappingOop(block))
        .map((block) => block.downtime);
    },
    selectedDowntime(state, getters) {
      return getters.downtimes.find((downtime) => downtime.id === state.selectedDowntimeId);
    },
    selectedDowntimeId(state) {
      return state.selectedDowntimeId;
    },
    isBlockDown() {
      return (blockState) =>
        blockState === "down_unplanned" ||
        blockState === "down_unjustified" ||
        blockState === "down_planned" ||
        blockState === "down_mixed";
    },
    isJustifiableDowntime(state, getters) {
      return (block) => {
        return block.duration >= getters.activePUJustificationDelayInSeconds;
      };
    },
    isBlockOverlappingOop(state, getters) {
      return (block) => {
        // return true if any OOP block is overlapping this block either partially or entirely
        const overlappingOopBlocks = getters.timeline.blocks.filter((b) => {
          return (
            b.start < block.start + block.duration &&
            b.start + b.duration > block.start &&
            b.state.toLowerCase() === "out_of_production"
          );
        });
        return overlappingOopBlocks.length > 0;
      };
    },
    justificationCommentMaxLength() {
      return 250;
    },
    productMarkers(state) {
      return state.timeline.markers.filter((m) => m.type === "ProductSelection");
    },
    shiftMarkers(state) {
      return state.timeline.markers.filter((m) => m.type === "WorkShiftStart");
    },
    conversionFactorOverrideMarkers(state) {
      return state.timeline.markers.filter((m) => m.type === "ConversionFactorOverride");
    },
    availableDowntimeReasons(state) {
      return state.availableDowntimeReasons;
    },
    availableRejectReasons(state) {
      return state.availableRejectReasons;
    },
    activeProductionUnit(state) {
      return state.activeProductionUnit;
    },
    activePUJustificationDelayInSeconds(state) {
      let delay = state.justificationDelayInSeconds;
      return delay && delay > 0 ? delay : 0;
    },
    activePUJustificationDelayInMillis(state) {
      let delay = state.justificationDelayInSeconds;
      return delay && delay > 0 ? 1000 * delay : 0;
    },
    availability(state) {
      return state.availability;
    },
    availabilityTarget(state) {
      const target = state.activeProductionUnitObjectives.find(
        (objective) => objective.objective_type === "availability",
      );
      if (target && target.value) {
        return Helpers.round(target.value, 1);
      } else {
        return null;
      }
    },
    performance(state) {
      return state.performance;
    },
    performanceTarget(state) {
      const target = state.activeProductionUnitObjectives.find(
        (objective) => objective.objective_type === "performance",
      );
      if (target && target.value) {
        return Helpers.round(target.value, 1);
      } else {
        return null;
      }
    },
    quality(state) {
      return state.quality;
    },
    rejectQuantity: (state) => (unit) => {
      if (!unit) return state.rejectQuantity;
      const unitType = getUnitType(unit);
      switch (unitType) {
        case "product_unit": {
          return state.rejectQuantityByUnit ? state.rejectQuantityByUnit[unit] : null;
        }
        case "weight_unit": {
          return convertKilogramTo(state.wlvRejectQuantity.totalWeight, unit);
        }
        case "length_unit": {
          return convertMeterTo(state.wlvRejectQuantity.totalLength, unit);
        }
        case "volume_unit": {
          return convertLiterTo(state.wlvRejectQuantity.totalVolume, unit);
        }
        default:
          return null;
      }
    },
    allProductsQuantity: (state) => (unit) => {
      if (!unit) return state.allProductsQuantity;
      const unitType = getUnitType(unit);
      switch (unitType) {
        case "product_unit": {
          return state.allProductsQuantityByUnit ? state.allProductsQuantityByUnit[unit] : null;
        }
        case "weight_unit": {
          const weightInKilogram = state.wlvQuantity?.total_weight?.total_value;
          if (weightInKilogram === undefined || weightInKilogram === null) return null;
          return convertKilogramTo(weightInKilogram, unit);
        }
        case "length_unit": {
          const lengthInMeter = state.wlvQuantity?.total_length?.total_value;
          if (lengthInMeter === undefined || lengthInMeter === null) return null;
          return convertMeterTo(lengthInMeter, unit);
        }
        case "volume_unit": {
          const volumeInLiter = state.wlvQuantity?.total_volume?.total_value;
          if (volumeInLiter === undefined || volumeInLiter === null) return null;
          return convertLiterTo(volumeInLiter, unit);
        }
        default:
          return null;
      }
    },
    currentProductQuantity: (state) => (unit) => {
      if (!unit) return state.currentProductQuantity;
      return state.currentProductQuantityByUnit ? state.currentProductQuantityByUnit[unit] : null;
    },
    currentProductThroughput: (state) => (unit) => {
      if (!unit) return state.currentProductThroughput;
      const unitType = getUnitType(unit);
      switch (unitType) {
        case "product_unit":
          return state.currentProductThroughputByUnit ? state.currentProductThroughputByUnit[unit] : null;
        case "weight_unit": {
          // throughput was asked to be shown in weight but the current wlv throughput is not in weight
          if (state.currentProductWLVThroughput.standardUnit !== "kg") return undefined;
          return convertKilogramTo(state.currentProductWLVThroughput.value, unit);
        }
        case "length_unit": {
          // throughput was asked to be shown in length but the current wlv throughput is not in length
          if (state.currentProductWLVThroughput.standardUnit !== "m") return undefined;
          return convertMeterTo(state.currentProductWLVThroughput.value, unit);
        }
        case "volume_unit": {
          // throughput was asked to be shown in volume but the current wlv throughput is not in volume
          if (state.currentProductWLVThroughput.standardUnit !== "l") return undefined;
          return convertLiterTo(state.currentProductWLVThroughput.value, unit);
        }
        default:
          return null;
      }
    },
    currentProductSpeed5m: (state) => (unit) => {
      if (!unit) return state.currentProductSpeed5m;
      const unitType = getUnitType(unit);
      switch (unitType) {
        case "product_unit": {
          if (!state.currentProductSpeed5mByUnit) {
            return null;
          } else {
            return state.currentProductSpeed5mByUnit[unit];
          }
        }
        case "weight_unit": {
          // throughput was asked to be shown in weight but the current wlv throughput is not in weight
          if (state.currentProductWLVSpeed5m.standardUnit === null) return null;
          if (state.currentProductWLVSpeed5m.standardUnit !== "kg") return undefined;
          return convertKilogramTo(state.currentProductWLVSpeed5m.value, unit);
        }
        case "length_unit": {
          // throughput was asked to be shown in length but the current wlv throughput is not in length
          if (state.currentProductWLVSpeed5m.standardUnit === null) return null;
          if (state.currentProductWLVSpeed5m.standardUnit !== "m") return undefined;
          return convertMeterTo(state.currentProductWLVSpeed5m.value, unit);
        }
        case "volume_unit": {
          // throughput was asked to be shown in volume but the current wlv throughput is not in volume
          if (state.currentProductWLVSpeed5m.standardUnit === null) return null;
          if (state.currentProductWLVSpeed5m.standardUnit !== "l") return undefined;
          return convertLiterTo(state.currentProductWLVSpeed5m.value, unit);
        }
        default:
          return null;
      }
    },
    timeDistribution(state) {
      return state.timeDistribution;
    },
    productGiveaway(state) {
      return state.productGiveaway;
    },
    averageProductGiveaway(state) {
      return state.averageProductGiveaway;
    },
    oee(state) {
      return state.oee; // Overall EQUIPMENT Effectiveness
    },
    oeeTarget(state) {
      const target = state.activeProductionUnitObjectives.find((objective) => objective.objective_type === "oee");
      if (target && target.value) {
        return Helpers.round(target.value, 1);
      } else {
        return null;
      }
    },
    ooe(state) {
      return state.ooe; // Overall OPERATION Effectiveness. Be careful; this is not the oee!
    },
    ooeTarget(state) {
      const target = state.activeProductionUnitObjectives.find((objective) => objective.objective_type === "ooe");
      if (target && target.value) {
        return Helpers.round(target.value, 1);
      } else {
        return null;
      }
    },
    totalWeightQuantity(state) {
      return state.wlvQuantity?.total_weight?.total_value;
    },
    totalVolumeQuantity(state) {
      return state.wlvQuantity?.total_volume?.total_value;
    },
    totalLengthQuantity(state) {
      return state.wlvQuantity?.total_length?.total_value;
    },
    qualityTarget(state) {
      const target = state.activeProductionUnitObjectives.find((objective) => objective.objective_type === "quality");
      if (target && target.value) {
        return Helpers.round(target.value, 1);
      } else {
        return null;
      }
    },
    oldestDataSourceAlert(state, getters, rootState, rootGetters) {
      const alerts = rootGetters["navigation/activeFactoryDataSourceAlerts"];
      if (alerts && alerts.length > 0) {
        let activePUAlerts = alerts.filter((a) => a.production_unit_ids.includes(state.activeProductionUnitId));
        activePUAlerts = activePUAlerts.sort(SortUtils.sortAlertsByDurationDescending);
        if (activePUAlerts.length > 0) {
          return activePUAlerts[0];
        } else {
          return null;
        }
      }
      return null;
    },
    isActiveTile: (state) => (tile) => {
      return state.tileSelection.find((id) => id === tile.name) !== undefined;
    },
    activeProductionUnitTags(state) {
      return state.activeProductionUnitTags;
    },
    activeDashboardPreferencesTiles(state, getters, rootState, rootGetters) {
      if (!state.activeProductionUnitId || state.activeProductionUnitId === "" || !rootGetters["user/preferences"])
        return null;

      const preferences = rootGetters["user/preferences"];
      const activeDashboardPreferences = preferences.dashboards.production_units.find(
        (p) => p.id === state.activeProductionUnitId,
      );
      return activeDashboardPreferences.tiles;
    },
    activeDashboardPreferencesGraph(state, getters, rootState, rootGetters) {
      if (!state.activeProductionUnitId || state.activeProductionUnitId === "" || !rootGetters["user/preferences"])
        return null;

      const preferences = rootGetters["user/preferences"];
      const activeDashboardPreferences = preferences.dashboards.production_units.find(
        (p) => p.id === state.activeProductionUnitId,
      );
      return activeDashboardPreferences.graph;
    },
  },

  actions: {
    setActiveProductionUnitId({ commit, dispatch, rootGetters }, activeProductionUnitId) {
      const factories = rootGetters["user/factories"];
      const pu = factories.reduce((foundPu, factory) => {
        // If already found, no need to iterate further
        if (foundPu) return foundPu;
        const pu = factory.productionUnits.find((pu) => pu.id === activeProductionUnitId);
        return pu;
      }, undefined);

      commit("setActiveProductionUnit", pu);
      commit("setActiveProductionUnitId", activeProductionUnitId);
      dispatch("user/updateSelectedProductionUnitId", activeProductionUnitId, { root: true });
      dispatch("setDashboardFromPreferences");
    },

    setDashboardFromPreferences({ commit, rootGetters, state }) {
      let dashboards = rootGetters["user/preferences"]?.dashboards;
      if (!dashboards) return;
      const tiles = dashboards.production_units.find((pu) => pu.id === state.activeProductionUnitId).tiles;
      const graph = dashboards.production_units.find((pu) => pu.id === state.activeProductionUnitId).graph;
      const tab = dashboards.production_units.find((pu) => pu.id === state.activeProductionUnitId).tab;

      const graphSelection = graph ?? "quantity";
      const tabSelection = tab ?? "timeline";

      const isGiveawayTabSelected = state.activeProductionUnit.packages.includes("SF4") && tabSelection === "giveaway";

      commit("setCurrentTileSelection", UserService.buildTileSelection(tiles));
      commit("setCurrentGraphSelection", graphSelection);
      commit("setGiveawayViewToggle", isGiveawayTabSelected);
    },

    setSamplingSessionInProgress({ commit }, samplingSession) {
      commit("setSamplingSessionInProgress", samplingSession);
    },

    fetchDashboard({ dispatch, state, rootGetters }) {
      dispatch("navigation/setCurrentDateTime", null, { root: true });
      const isUserLoggedIn = rootGetters["user/isLoggedIn"];
      if (!isUserLoggedIn || !state.activeProductionUnitId) return;

      const selectedProductionRun = rootGetters["navigation/selectedProductionRun"];
      const optionalProductionRunOptions = selectedProductionRun
        ? {
            sku: selectedProductionRun.sku,
            workOrderId: selectedProductionRun.work_order_id,
            lotId: selectedProductionRun.lot_id,
          }
        : null;

      if (!rootGetters["navigation/startISO"] || !rootGetters["navigation/endISO"]) return;

      dispatch("fetchProductionUnit").then(() => {
        dispatch("fetchTimelineAndGraphElements").then(() => {
          dispatch("fetchDashboardMetrics", { optionalProductionRunOptions });
        });
      });
    },

    async fetchTimelineAndGraphElements({ dispatch, getters, rootGetters, commit }) {
      // stop here if we're only fetching the metrics
      if (isDurationGreaterThanNDays(rootGetters["navigation/durationMillis"], 3)) {
        commit("setLoader", ["isTimelineLoading", false]);
        commit("setLoader", ["isGraphLoading", false]);
        commit("setLoader", ["isTimelineLoaded", true]);
        commit("setLoader", ["isGraphLoaded", true]);
        return;
      }
      const timeAggregation = getQuantityGraphTimeAggregation(rootGetters["navigation/durationMillis"]);
      if (getters.giveawayViewToggle) {
        // only fetch giveaway graph elements
        await dispatch("fetchGiveawayGraphElements");
      } else {
        // fetch timeline and graph elements
        await dispatch("fetchTimelineElements");
        await dispatch("fetchGraphElements", { timeAggregation });
      }
    },

    fetchProductionUnit({ commit, state }) {
      ProductionUnitService.getProductionUnit(state.activeProductionUnitId)
        .then((httpResponse) => {
          if (httpResponse.status === 200) {
            let productionUnit = httpResponse.data;
            commit("setActiveProductionUnit", productionUnit);
            commit("setActiveProductionUnitObjectives", productionUnit.objectives);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },

    fetchTimelineElements({ commit, dispatch, state, rootGetters }) {
      if (state.isTimelineLoading) return;
      commit("setLoader", ["isTimelineLoading", true]);
      return TimelineService.getTimelineElements(
        state.activeProductionUnitId,
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
      )
        .then((response) => {
          let startingSku = response.data.starting_sku;
          commit("setStartingSku", startingSku);
          let currentProduct = response.data.current_product;
          commit("setCurrentProduct", currentProduct);
          if (currentProduct && currentProduct.id) {
            dispatch("fetchProductObjectives", currentProduct.id);
          }
          commit("setCurrentWorkOrder", response.data.current_work_order);
          commit("setCurrentLot", response.data.current_lot);
          commit("setCycleStats", response.data.cycle_time_stats);
          commit("setTimeline", response.data);
          commit("setLoader", ["isTimelineLoading", false]);
          commit("setLoader", ["isTimelineLoaded", true]);
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
          commit("setLoader", ["isTimelineLoading", false]);
          commit("setLoader", ["isTimelineLoaded", true]);
        });
    },

    fetchProductObjectives({ commit }, productId) {
      const includeDeleted = true;
      return ProductService.getProduct(productId, includeDeleted)
        .then((httpResponse) => {
          if (httpResponse.status === 200) {
            let product = httpResponse.data;
            if (product && product.associated_production_units) {
              commit("setCurrentProductPUAssociations", product.associated_production_units);
            }
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },

    fetchGraphElements({ commit, getters, state, rootGetters }, { timeAggregation, optionalProductionRunOptions }) {
      if (getters.giveawayViewToggle) return;
      if (state.isGraphLoading) return;

      commit("setLoader", ["isGraphLoading", true]);
      GraphService.getGraphElements(
        state.activeProductionUnitId,
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
        timeAggregation,
        optionalProductionRunOptions,
      )
        .then((response) => {
          // If the following condition is FALSE, the request was called for a production line,
          // and the user switched lines before the call finished, in effect "cancelling" the previous
          // call and launching a new one.
          // When the initial call returns, ignore it since it will be setting wrong data, and setting
          // the refreshing running variable to false when the second one might not be finished.
          const requestForProductionUnitId = response.config.url.split("/")[2];
          if (state.activeProductionUnitId === requestForProductionUnitId) {
            commit("setGraph", response.data);
          }
          commit("setLoader", ["isGraphLoading", false]);
          commit("setLoader", ["isGraphLoaded", true]);
        })
        .catch((error) => {
          commit("setLoader", ["isGraphLoading", false]);
          commit("setLoader", ["isGraphLoaded", true]);
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
        });
    },

    fetchGiveawayGraphElements({ commit, getters, state, rootGetters }) {
      if (state.isGiveawayGraphLoading) return;
      // make sure the chart type provided id a valid one
      // if (["sampling", "check_weigher"].find(chartType) === undefined) return;
      if (
        !rootGetters["packages/activePuHasRequiredFeature"](Tiles.productGiveaway.requiredFeature) ||
        !getters.giveawayViewToggle
      ) {
        // The active Production Unit is not configured with the feature `Giveaway`, leave it to `null`.
        commit("setGiveawayGraph", null);
        return;
      }

      commit("setLoader", ["isGiveawayGraphLoading", true]);
      let chartType = "check_weigher";
      if (state.activeProductionUnit.giveaway_sampling_configuration !== null) chartType = "sampling"; // switch to sampling if configuration exists for PU

      GraphService.getGiveawayElements(
        state.activeProductionUnitId,
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
        chartType,
      )
        .then((response) => {
          commit("setGiveawayGraph", response.data);
          commit("setLoader", ["isGiveawayGraphLoading", false]);
          commit("setLoader", ["isGiveawayGraphLoaded", true]);
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
          commit("setLoader", ["isGiveawayGraphLoading", false]);
          commit("setLoader", ["isGiveawayGraphLoaded", true]);
        });
    },

    fetchAvailableDowntimeReasons({ commit, state }, { downtimeId }) {
      DowntimeJustificationService.getAvailableDowntimeReasons(state.activeProductionUnitId, downtimeId)
        .then((response) => {
          if (response.status === 200) {
            commit("setAvailableDowntimeReasons", response.data);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },

    fetchAvailableRejectReasons({ commit, state }) {
      return RejectReasonService.getAvailableRejectReasons(state.activeProductionUnitId)
        .then((response) => {
          if (response.status === 200) {
            commit("setAvailableRejectReasons", response.data);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },

    submitRejects({ commit, state, rootGetters }, { rejectPayload }) {
      if (!rootGetters["packages/activePuHasRequiredFeature"](PackageFeatures.reject)) {
        let errorMessage = i18n.t("dashboard.manualRejectsEntry.errors.puMissingPackage", {
          puName: state.activeProductionUnit.name,
        });
        commit("operation/showOperationError", errorMessage, { root: true });
        return;
      }
      return RejectService.submitReject(state.activeProductionUnitId, rejectPayload).then(() => {
        commit("operation/showOperationSuccess", i18n.t("dashboard.manualRejectsEntry.successfullyApplied"), {
          root: true,
        });
      });
    },

    updateProductionRun({ commit, state }, productionRunRequest) {
      const productionRunId = productionRunRequest.eventId;
      let request = productionRunRequest;
      delete request.eventId;
      return ProductionRunService.updateProductionRun(state.activeProductionUnitId, productionRunId, request).then(
        () => {
          commit("operation/showOperationSuccess", i18n.t("dashboard.productionRun.successfullyUpdated"), {
            root: true,
          });
        },
      );
    },

    createProductionRun({ commit, state, rootGetters }, productionRunRequest) {
      return ProductionRunService.createProductionRun(state.activeProductionUnitId, productionRunRequest).then(
        (httpResponse) => {
          const startTimeMillisUtc = httpResponse.data.effective_start_time;
          const startTime = TimeUtils.getTimeFromMillis(
            startTimeMillisUtc,
            rootGetters["navigation/activeFactory"]?.timezone,
          );
          const startDate = TimeUtils.getDateFromMillis(
            startTimeMillisUtc,
            rootGetters["navigation/activeFactory"]?.timezone,
          );
          commit(
            "operation/showOperationSuccess",
            i18n.t("dashboard.productionRun.successfullyCreated", { startDate, startTime }),
            {
              root: true,
            },
          );
        },
      );
    },

    deleteProductionRun({ commit, state }, productionDeletionRequest) {
      return ProductionRunService.deleteProductionRun(
        state.activeProductionUnitId,
        productionDeletionRequest.productionUnitEventId,
        productionDeletionRequest.forwardedProductionUnitIds,
        productionDeletionRequest.forwardedProductionUnitGroup,
      ).then(() => {
        commit(
          "operation/showOperationSuccess",
          i18n.t("dashboard.productionRun.deleteProductionRun.successfullyDeleted"),
          {
            root: true,
          },
        );
      });
    },

    createConversionFactorOverride({ commit, state }, request) {
      return ProductionRunService.createConversionFactorOverride(state.activeProductionUnitId, request).then(() => {
        const dateTime = DateTime.fromISO(request.effective_start_time);
        const date = dateTime.toFormat(TimeUtils.DATE_FORMAT);
        const time = dateTime.toFormat(TimeUtils.TIME_FORMAT);
        commit(
          "operation/showOperationSuccess",
          i18n.t("dashboard.conversionFactorOverride.successfullyCreated", { startDate: date, startTime: time }),
          {
            root: true,
          },
        );
      });
    },

    updateConversionFactorOverride({ commit, state }, request) {
      const eventId = request.event_id;
      delete request.event_id;
      return ProductionRunService.updateConversionFactorOverride(state.activeProductionUnitId, eventId, request).then(
        () => {
          commit("operation/showOperationSuccess", i18n.t("dashboard.conversionFactorOverride.successfullyUpdated"), {
            root: true,
          });
        },
      );
    },

    deleteConversionFactorOverride({ commit, state }, eventId) {
      return ProductionRunService.deleteConversionFactorOverride(state.activeProductionUnitId, eventId).then(() => {
        commit(
          "operation/showOperationSuccess",
          i18n.t("dashboard.conversionFactorOverride.delete.successfullyDeleted"),
          {
            root: true,
          },
        );
      });
    },

    selectDowntime({ commit }, id) {
      commit("setSelectedDowntime", id);
    },

    selectPreviousDowntime({ commit, getters, state }) {
      const justifiableDowntimes = getters.downtimes.filter(
        (downtime) => downtime.end_time - downtime.start_time >= getters.activePUJustificationDelayInMillis,
      );
      let index = justifiableDowntimes.findIndex((downtime) => downtime.id === state.selectedDowntimeId);
      if (index > 0) {
        commit("setSelectedDowntime", justifiableDowntimes[index - 1].id);
      }
    },

    selectNextDowntime({ commit, getters, state }) {
      const justifiableDowntimes = getters.downtimes.filter(
        (downtime) => downtime.end_time - downtime.start_time >= getters.activePUJustificationDelayInMillis,
      );
      let index = justifiableDowntimes.findIndex((downtime) => downtime.id === state.selectedDowntimeId);
      if (index < justifiableDowntimes.length - 1) {
        commit("setSelectedDowntime", justifiableDowntimes[index + 1].id);
      }
    },

    selectLastDowntime({ commit, getters }) {
      const justifiableDowntimes = getters.downtimes.filter(
        (downtime) => downtime.end_time - downtime.start_time >= getters.activePUJustificationDelayInMillis,
      );
      const lastDowntimeId = justifiableDowntimes[justifiableDowntimes.length - 1]?.id;
      if (lastDowntimeId) {
        commit("setSelectedDowntime", lastDowntimeId);
      }
    },

    selectPreviousDowntimeUnjustified({ commit, getters }) {
      let olderUnjustifiedDowntimes = getters.unjustifiedDowntimesBeforeSelected;
      if (olderUnjustifiedDowntimes.length > 0) {
        commit("setSelectedDowntime", olderUnjustifiedDowntimes[olderUnjustifiedDowntimes.length - 1].id);
      }
    },

    selectLastDowntimeUnjustified({ commit, getters }) {
      let lastDowntimeId = null;

      const lastDowntimeUnjustified = getters.unjustifiedDowntimes[getters.unjustifiedDowntimes.length - 1];
      const lastDowntimeUnjustifiedId = lastDowntimeUnjustified?.id;

      const lastPartiallyJustifiedDowntime = getters.partiallyJustifiedDowntimes[getters.partiallyJustifiedDowntimes.length - 1];
      const lastPartiallyJustifiedDowntimeId = lastPartiallyJustifiedDowntime?.id;

      if (lastDowntimeUnjustified && lastPartiallyJustifiedDowntime && lastDowntimeUnjustified.start_time > lastPartiallyJustifiedDowntime.start_time) {
        lastDowntimeId = lastDowntimeUnjustifiedId;
      } else if (lastDowntimeUnjustified && lastPartiallyJustifiedDowntime && lastDowntimeUnjustified.start_time < lastPartiallyJustifiedDowntime.start_time) {
        lastDowntimeId = lastPartiallyJustifiedDowntimeId;
      } else if (lastDowntimeUnjustified && (lastPartiallyJustifiedDowntime === null || lastPartiallyJustifiedDowntime === undefined)) {
        lastDowntimeId = lastDowntimeUnjustifiedId;
      } else if (lastPartiallyJustifiedDowntime && (lastDowntimeUnjustified === null || lastDowntimeUnjustified === undefined)) {
        lastDowntimeId = lastPartiallyJustifiedDowntimeId;
      }
      
      if (lastDowntimeId) {
        commit("setSelectedDowntime", lastDowntimeId);
      }
    },

    unselectDowntime({ commit }) {
      commit("setSelectedDowntime", null);
    },

    setCurrentDowntimeDuration({ commit, getters, state, rootGetters }) {
      let downtime = getters.currentDowntime;
      if (downtime) {
        const isLiveData = rootGetters["navigation/isLiveData"];
        if (isLiveData) {
          let now = Date.now();
          commit("setCurrentDowntimeDuration", ((now - downtime.start_time) / 1000) * 1000);
        } else {
          commit("setCurrentDowntimeDuration", 0);
        }
      } else if (state.currentDowntimeDuration > 0) {
        commit("setCurrentDowntimeDuration", 0);
      }
    },

    fetchDashboardMetrics({ commit, dispatch, state, getters, rootGetters }, { optionalProductionRunOptions }) {
      if (state.isMetricsLoading) return;
      commit("setLoader", ["isMetricsLoading", true]);
      let timeframe = rootGetters["navigation/timeFrame"];
      TileService.getProductionUnitMetrics(
        state.activeProductionUnitId,
        timeframe,
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
        rootGetters["navigation/date"], // Business Day as selected by the user
        optionalProductionRunOptions,
      )
        .then((response) => {
          commit("setLoader", ["isMetricsLoading", false]);

          let currentSku = null;
          if (getters.giveawayViewToggle) {
            currentSku = state.giveawaySku;
          } else if (optionalProductionRunOptions) {
            currentSku = optionalProductionRunOptions.sku;
          } else {
            currentSku = getters.currentProductSku ? getters.currentProductSku : "";
          }

          let metricsData = response.data[0];
          if (response.status === 200 && metricsData) {
            metricsData = metricsData.metrics;
          }
          commit("setMetrics", { metrics: metricsData, currentSku: currentSku });
          commit("setAverageProductGiveaway", currentSku);
          commit("setProductGiveaway", currentSku);
          if (timeframe === PRODUCTION_RUN) {
            commit("setCurrentProductionRunMetrics", response.data[0].metrics);
          }
          commit("setLoader", ["isMetricsLoaded", true]);
          dispatch("fetchCurrentProductSpeed5m");
        })
        .catch((error) => {
          commit("setLoader", ["isMetricsLoading", false]);
          commit("setLoader", ["isMetricsLoaded", true]);
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
        });
      dispatch("fetchCurrentProductionRun");
    },

    fetchCurrentProductSpeed5m({ commit, state, getters, rootGetters }) {
      if (
        !getters.isActiveTile(Tiles.currentProductSpeed5m) &&
        !getters.isActiveTile(Tiles.currentProductSpeed5mPerMinute)
      )
        return;

      if (rootGetters["navigation/isLiveData"]) {
        const currentTimelineCursorTime = rootGetters["navigation/currentDateTime"];
        const coverageEndDateTime = currentTimelineCursorTime.set({ seconds: 0, milliseconds: 0 }).plus({ minutes: 1 });
        const coverageStartDateTime = coverageEndDateTime.minus({ minutes: 5 });
        const coverageStart = coverageStartDateTime.toFormat("yyyy-LL-dd'T'HH:mm:ssZZ");
        const coverageEnd = coverageEndDateTime.toFormat("yyyy-LL-dd'T'HH:mm:ssZZ");

        TileService.getProductSpeed(state.activeProductionUnitId, coverageStart, coverageEnd, getters.currentProductSku)
          .then((response) => {
            const data = response.data;
            commit("setCurrentProductSpeed5m", {
              value: data.product_speed,
              valueByUnit: data.speed_by_unit,
              wlvValue: data.weight_length_volume_speed,
              wlvUnit: data.weight_length_volume_speed_unit,
            });
          })
          .catch((error) =>
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, (code) =>
                i18n.t("common.errors.default", { code: code }),
              ),
              { root: true },
            ),
          );
      } else {
        commit("setCurrentProductSpeed5m", { value: null, valueByUnit: null, wlvValue: null, wlvUnit: null });
      }
    },

    setGiveawayForSku({ commit }, sku) {
      commit("setProductGiveaway", sku);
    },

    setAverageProductGiveawayForSku({ commit }, sku) {
      commit("setAverageProductGiveaway", sku);
    },

    fetchCurrentProductionRun({ commit, state, rootGetters, getters, dispatch }) {
      dispatch("navigation/setCurrentDateTime", null, { root: true });
      const isUsingProductionTile = getters.isActiveTile(Tiles.production);
      const isUsingProductionCompletion = getters.isActiveTile(Tiles.timeToCompletion);
      if (!isUsingProductionTile && !isUsingProductionCompletion) return;

      // use latest production run coverage
      const prodRun = rootGetters["navigation/selectedProductionRun"];
      if (!prodRun) return;

      const productionUnitId = state.activeProductionUnitId;
      let timeframe = rootGetters["navigation/timeFrame"];
      if (timeframe === PRODUCTION_RUN) {
        commit("setCurrentProductionRun", prodRun);
      } else {
        // dispatch uses an Array
        const productionUnitIds = [productionUnitId];
        const startDate = prodRun.start_date;
        const endDate = prodRun.end_date;
        dispatch(
          "overview/fetchProductionUnitsProductionRunMetrics",
          { productionUnitIds, startDate, endDate },
          { root: true },
        )
          .then(() => {
            const productionUnitProductionRunMetrics = rootGetters["overview/productionUnitsByProductionRunMetrics"];
            const productionRunMetricsForPu = productionUnitProductionRunMetrics.find(
              (metric) =>
                metric.key.production_unit_id === productionUnitId &&
                metric.key.sku === prodRun.sku &&
                metric.key.work_order_id === prodRun.work_order_id &&
                metric.key.lot_id === prodRun.lot_id &&
                metric.key.start_time === TimeUtils.toEpochMillisUTC(prodRun.start_date),
            );
            commit("setCurrentProductionRun", prodRun);
            const metrics = productionRunMetricsForPu?.metrics ?? null;
            commit("setCurrentProductionRunMetrics", metrics);

            commit("setLoader", ["isCurrentProductionRunLoading", false]);
            commit("setLoader", ["isCurrentProductionRunLoaded", true]);
          })
          .catch(() => {
            // No need to handle error here since it is handled in the dispatch call.
            commit("setLoader", ["isCurrentProductionRunLoading", false]);
            commit("setLoader", ["isCurrentProductionRunLoaded", true]);
          });
      }
    },

    setTileByIndex({ commit, dispatch }, { tile, index, optionalTileConfig }) {
      commit("setTileByIndex", { tile, index });
      dispatch(
        "user/updateActiveProductionUnitTileSelection",
        { config: optionalTileConfig, index: index, name: tileKeyByName[tile] },
        { root: true },
      );
    },

    setJustificationDelayInSeconds({ commit }, delayInSeconds) {
      commit("setJustificationDelayInSeconds", delayInSeconds);
    },

    setGiveawayViewToggle({ commit }, value) {
      commit("setGiveawayViewToggle", value);
    },

    async fetchProductionUnitCoverage({ commit, state, rootGetters, dispatch }, coverage) {
      dispatch("navigation/setCurrentDateTime", null, { root: true });
      let isUserLoggedIn = rootGetters["user/isLoggedIn"];
      let factoryId = rootGetters["navigation/activeFactory"].id;
      if (!isUserLoggedIn || !state.activeProductionUnitId) return;
      return TimelineService.getProductionUnitCoverage(
        factoryId,
        state.activeProductionUnitId,
        coverage.startTime,
        coverage.endTime,
      )
        .then((response) => {
          const productionUnitCoverage = response.data["production_units_coverage"]?.find(
            (puc) => puc.production_unit_id === state.activeProductionUnitId,
          );
          if (!productionUnitCoverage) {
            // should never happen, if production unit does not exist API returns an error
            // in this case should we fail gracefully or throw an error and show
            // operation error?
            commit("setProductionRunsCoverage", []);
            commit("setWorkShiftsCoverage", []);
          }
          const sortedProductionRunsCoverage = productionUnitCoverage["production_runs_coverage"].sort(
            SortUtils.sortCoverageByStartDateDesc,
          );
          commit("setProductionRunsCoverage", sortedProductionRunsCoverage);

          productionUnitCoverage["work_shifts_coverage"].forEach((ws) => {
            ws["start_time_millis"] = DateTime.fromISO(ws.start_date).toMillis();
            ws["end_time_millis"] = DateTime.fromISO(ws.end_date).toMillis();
          });
          commit("setWorkShiftsCoverage", productionUnitCoverage["work_shifts_coverage"]);
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              switch (code) {
                case "DSH_COV_GET_10007":
                case "DSH_COV_GET_10008":
                  return null; // No error messages for this backend error code. It's a race condition.
                default:
                  return i18n.t("common.errors.default", { code: error });
              }
            }),
            { root: true },
          );
        });
    },

    fetchProductionUnitProducts({ commit, state, rootGetters }) {
      if (!rootGetters["navigation/activeFactory"]) return;
      const activeProductionUnitId = state.activeProductionUnitId;
      if (activeProductionUnitId !== "" && activeProductionUnitId !== null && activeProductionUnitId !== undefined) {
        ProductService.getProductionUnitProducts(activeProductionUnitId)
          .then((response) => {
            if (response.status === 200) {
              commit("setProductionUnitProducts", response.data);
            }
          })
          .catch((error) =>
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, (code) =>
                i18n.t("common.errors.default", { code: code }),
              ),
              { root: true },
            ),
          );
      }
    },

    fetchProductionUnitTags({ commit, state }) {
      const activeProductionUnitId = state.activeProductionUnitId;
      if (!activeProductionUnitId || activeProductionUnitId === "") return;

      ProductionUnitService.getProductionUnitTags(activeProductionUnitId)
        .then((httpResponse) => {
          commit("setProductionUnitTags", httpResponse.data);
        })
        .catch((error) => {
          console.error(error);
          commit("setProductionUnitTags", []);
        });
    },

    setActiveProductionUnitTag({ commit, state }, tagId) {
      const activeProductionUnitId = state.activeProductionUnitId;
      if (!activeProductionUnitId || activeProductionUnitId === "") return;

      ProductionUnitService.updateProductionUnitTag(activeProductionUnitId, tagId)
        .then((httpResponse) => {
          const activeTags = state.activeProductionUnitTags;
          activeTags.unshift(httpResponse.data); // set at the beginning of the array to be active
          commit("setProductionUnitTags", activeTags);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    removeActiveProductionUnitTag({ commit, state }, tagId) {
      const activeProductionUnitId = state.activeProductionUnitId;
      if (!activeProductionUnitId || activeProductionUnitId === "") return;

      ProductionUnitService.deleteProductionUnitTag(activeProductionUnitId, tagId)
        .then(() => {
          const activeTags = state.activeProductionUnitTags;
          const deletedTagIndex = activeTags.findIndex((t) => t.id === tagId);
          if (deletedTagIndex >= 0) {
            activeTags.splice(deletedTagIndex, 1); // remove the deleted tag from the list of active tags
            commit("setProductionUnitTags", activeTags);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    resetDashboardLoaders({ commit }) {
      commit("setLoader", ["isMetricsLoaded", false]);
      commit("setLoader", ["isCurrentProductionRunLoaded", false]);
      commit("setLoader", ["isTimelineLoaded", false]);
      commit("setLoader", ["isGraphLoaded", false]);
      commit("setLoader", ["isGiveawayGraphLoaded", false]);
    },
  },

  mutations: {
    setLoader(state, [loader, value]) {
      state[loader] = value;
    },
    setGiveawayViewToggle(state, isGiveawayView) {
      state.giveawayViewToggle = isGiveawayView;
    },
    setActiveProductionUnitId(state, activeProductionUnitId) {
      state.activeProductionUnitId = activeProductionUnitId;
    },
    setSamplingSessionInProgress(state, samplingSession) {
      state.samplingSessionInProgress = samplingSession;
    },
    setCurrentTileSelection(state, newCurrentTileSelection) {
      state.tileSelection = newCurrentTileSelection;
    },
    setTileByIndex(state, { tile, index }) {
      Vue.set(state.tileSelection, index, tile);
    },
    setCurrentProduct(state, product) {
      state.currentProduct = product;
    },
    setCurrentProductionRun(state, production) {
      state.currentProductionRun = production;
    },
    setCurrentWorkOrder(state, workOrder) {
      state.currentWorkOrder = workOrder;
    },
    setCurrentLot(state, lot) {
      state.currentLot = lot;
    },
    setStartingSku(state, sku) {
      state.startingSku = sku;
    },
    setCurrentProductPUAssociations(state, associations) {
      state.currentProductPUAssociations = associations;
    },
    setCycleStats(state, cycleStats) {
      state.cycleStats = cycleStats;
    },
    setTimeline(state, payload) {
      state.timeline.offsetFromMidnight = payload.start.offset_from_midnight;
      state.timeline.coverageProgress = payload.coverage.elapsed_time;
      state.timeline.coverageDuration = payload.coverage.total_duration;
      state.timeline.markers = payload.markers;
      state.timeline.blocks = getTimelineBlocks(payload.blocks, payload.markers, payload.coverage.total_duration);
    },
    setGraph(state, payload) {
      // Set OEE Graph
      state.oeeGraph.coverageDuration = payload.coverage.total_duration;
      state.oeeGraph.interval = payload.graph_oee.interval_in_seconds;

      state.oeeGraph.offset = payload.graph_oee.offset_from_start;
      state.oeeGraph.target = payload.graph_oee.target;
      state.oeeGraph.labels = payload.graph_oee.values.map(() => "");
      state.oeeGraph.curveData = payload.graph_oee.values;
      state.oeeGraph.oeeSources = payload.graph_oee.oee_sources.map((s) => ({
        timestamp: s.timestamp,
        availability: s.a,
        performance: s.p,
        quality: s.q,
        totalQuantity: s.qty,
        totalQuantityBySku: s.qty_by_sku,
      }));

      // Set Quantity Graph
      state.quantityGraph.labels = payload.graph_sku_quantity.skus_intervals.map((x) => {
        return { start_time: x.start_time, end_time: x.end_time };
      });

      state.quantityGraph.quantityData = payload.graph_sku_quantity.skus_intervals
        // keep data points with quantities
        .filter((x) => x.sku_quantities != null)
        // build quantities with start and end time
        .map((x) => {
          return x.sku_quantities.map((y) => {
            return {
              start_time: x.start_time,
              end_time: x.end_time,
              ...y,
            };
          });
        })
        .flat()
        // Prepare data for stacked bar chart by grouping quantities by sku
        .reduce((prev, cur) => {
          if (!prev[cur["sku"]]) {
            prev[cur["sku"]] = [];
          }
          prev[cur["sku"]].push(cur);
          return prev;
        }, {});
    },
    setGiveawayGraph(state, payload) {
      let products = payload.products.map((x) => {
        let product = { ...x };

        if (!x.product_description) {
          product.product_description = null;
        }

        if (!x.upper_tolerance) {
          product.upper_tolerance = null;
        }

        if (!x.lower_tolerance) {
          product.lower_tolerance = null;
        }

        if (!x.target_value) {
          product.target_value = null;
        }

        if (!x.target_unit) {
          product.target_unit = null;
        }

        return product;
      });
      payload.products = products;
      state.giveawayGraph = payload;
    },
    setSelectedDowntime(state, downtimeId) {
      state.selectedDowntimeId = downtimeId;
    },
    setAvailableDowntimeReasons(state, payload) {
      state.availableDowntimeReasons = payload;
    },
    setAvailableRejectReasons(state, payload) {
      state.availableRejectReasons = payload;
    },
    setCurrentDowntimeDuration(state, value) {
      state.currentDowntimeDuration = value;
    },
    setActiveProductionUnitObjectives(state, value) {
      state.activeProductionUnitObjectives = value;
    },
    setActiveProductionUnit(state, value) {
      state.activeProductionUnit = value;
      state.justificationDelayInSeconds = value.downtime_justification_delay_in_seconds;
    },
    setCurrentProductionRunMetrics(state, value) {
      state.currentProductionRunMetrics = value;
    },
    setMetrics(state, metricsAndCurrentSku) {
      if (metricsAndCurrentSku.metrics) {
        const metrics = metricsAndCurrentSku.metrics;
        const currentSku = metricsAndCurrentSku.currentSku ?? "";
        state.timeDistribution = {
          uptime: metrics.time_distribution.total_uptime,
          downtime_planned: metrics.time_distribution.total_planned_downtime,
          downtime_unplanned: metrics.time_distribution.total_unplanned_downtime,
          downtime_unjustified: metrics.time_distribution.total_unjustified_downtime,
          out_of_production_time: metrics.time_distribution.total_out_of_production_time,
          unknown_time: metrics.time_distribution.total_unknown_time,
          total_available_time: metrics.time_distribution.total_available_time,
          total_production_time: metrics.time_distribution.total_production_time,
          time_distribution_by_sku: metrics.time_distribution.time_distribution_by_sku,
        };

        state.allProductsQuantity = metrics.produced_quantity.total_count;
        state.allProductsQuantityByUnit = metrics.produced_quantity.total_count_by_unit;
        state.currentProductQuantity = metrics.produced_quantity.quantity_by_sku[currentSku]?.count;
        state.currentProductQuantityByUnit = metrics.produced_quantity.quantity_by_sku[currentSku]?.count_by_unit;
        state.currentProductThroughput = metrics.produced_quantity.quantity_by_sku[currentSku]?.count_throughput;
        state.currentProductThroughputByUnit = metrics.performance.performance_by_sku[currentSku]?.throughput_by_unit;
        state.currentProductProductionObjective = metrics.performance.performance_by_sku[currentSku]?.production_objective;
        state.currentProductProductionObjectiveByUnit = metrics.performance.performance_by_sku[currentSku]?.production_objective_by_unit;
        state.currentProductWLVThroughput = {
          standardUnit: metrics.weight_length_volume_quantity.quantity_by_sku[currentSku]?.count_and_quantity?.standard_unit,
          value: metrics.weight_length_volume_quantity.quantity_by_sku[currentSku]?.weight_length_volume_throughput?.value,
          target: metrics.weight_length_volume_quantity.quantity_by_sku[currentSku]?.weight_length_volume_throughput?.cadence_objective,
        };

        state.availability = metrics.availability.value;
        state.performance = metrics.performance.value;
        state.quality = metrics.quality.value;
        state.oee = metrics.oee;
        state.ooe = metrics.ooe;

        state.rejectQuantity = metrics.reject_quantity.total_count;
        state.rejectQuantityByUnit = metrics.reject_quantity.total_count_by_unit;
        state.wlvRejectQuantity = {
          totalWeight: metrics.reject_quantity.total_weight,
          totalLength: metrics.reject_quantity.total_length,
          totalVolume: metrics.reject_quantity.total_volume,
        };

        state.wlvQuantity = metrics.weight_length_volume_quantity;
        state.giveaway = metrics.giveaway;
      } else {
        state.timeDistribution = {
          uptime: 0,
          downtime_planned: 0,
          downtime_unplanned: 0,
          downtime_unjustified: 0,
          out_of_production_time: 0,
          unknown_time: 0,
          total_available_time: 0,
          total_production_time: 0,
          time_distribution_by_sku: {},
        };

        state.allProductsQuantity = 0;
        state.allProductsQuantityByUnit = null;
        state.currentProductQuantity = 0;
        state.currentProductQuantityByUnit = null;
        state.currentProductThroughput = 0;
        state.currentProductThroughputByUnit = null;
        state.currentProductProductionObjective = null;
        state.currentProductProductionObjectiveByUnit = null;
        state.currentProductWLVThroughput = {
          standardUnit: null,
          value: null,
          target: null,
        };

        state.availability = 0;
        state.performance = 0;
        state.quality = 0;
        state.oee = 0;
        state.ooe = 0;

        state.rejectQuantity = 0;
        state.rejectQuantityByUnit = null;
        state.wlvRejectQuantity = {
          totalWeight: null,
          totalLength: null,
          totalVolume: null,
        };

        state.wlvQuantity = 0;
        state.giveaway = 0;
      }
    },
    setCurrentProductSpeed5m(state, { value, valueByUnit, wlvValue, wlvUnit }) {
      state.currentProductSpeed5m = value;
      state.currentProductSpeed5mByUnit = valueByUnit;
      state.currentProductWLVSpeed5m = {
        value: wlvValue,
        standardUnit: wlvUnit,
      };
    },
    setProductGiveaway(state, sku) {
      state.giveawaySku = sku;
      const currentSku = sku;
      const weightLengthVolumeQuantity = state.wlvQuantity;
      const giveaway = state.giveaway;

      const wlvQuantityForSKU = weightLengthVolumeQuantity?.[currentSku];
      const giveawayForCurrentSKU = giveaway.giveaway_by_sku[currentSku];

      let qty = giveawayForCurrentSKU?.giveaway_quantity;
      if (qty === null || qty === undefined) {
        qty = 0.0;
      }
      let configuredUnit = wlvQuantityForSKU?.count_and_quantity?.configured_unit
        ? wlvQuantityForSKU?.count_and_quantity?.configured_unit
        : "count";
      let standardUnit = wlvQuantityForSKU?.count_and_quantity?.standard_unit
        ? wlvQuantityForSKU?.count_and_quantity?.standard_unit
        : "count";
      let quantityInConfiguredUnit = qty;
      if (configuredUnit && standardUnit && configuredUnit !== "count" && standardUnit !== "count") {
        quantityInConfiguredUnit = convert(qty)
          .from(standardUnit)
          .to(configuredUnit);
      }
      state.productGiveaway.value = Helpers.round(quantityInConfiguredUnit, 1);
      state.productGiveaway.valuePercent = Helpers.round(giveawayForCurrentSKU?.giveaway_percentage, 1);
      state.productGiveaway.target = giveawayForCurrentSKU?.product_target;
      state.productGiveaway.targetPercent = giveawayForCurrentSKU?.configured_objective_percentage;
      state.productGiveaway.unit = configuredUnit;
    },
    setAverageProductGiveaway(state, sku) {
      state.giveawaySku = sku;
      const currentSku = sku;
      const weightLengthVolumeQuantity = state.wlvQuantity;
      const giveaway = state.giveaway;

      const wlvQuantityForSKU = weightLengthVolumeQuantity?.quantity_by_sku[currentSku];
      const giveawayForCurrentSKU = giveaway.giveaway_by_sku[currentSku];

      let qty = wlvQuantityForSKU?.count_and_quantity?.average_in_standard_unit;
      if (qty === null || qty === undefined) {
        qty = 0.0;
      }
      let configuredUnit = wlvQuantityForSKU?.count_and_quantity?.configured_unit
        ? wlvQuantityForSKU?.count_and_quantity?.configured_unit
        : "count";
      let standardUnit = wlvQuantityForSKU?.count_and_quantity?.standard_unit
        ? wlvQuantityForSKU?.count_and_quantity?.standard_unit
        : "count";
      let quantityInConfiguredUnit = qty;
      if (configuredUnit && standardUnit && configuredUnit !== "count" && standardUnit !== "count") {
        quantityInConfiguredUnit = convert(qty)
          .from(standardUnit)
          .to(configuredUnit);
      }
      let targetValueInConfiguredUnit = giveawayForCurrentSKU?.product_target;
      if (targetValueInConfiguredUnit) {
        targetValueInConfiguredUnit = convert(targetValueInConfiguredUnit)
          .from(standardUnit)
          .to(configuredUnit);
      }
      let roundedAverageValue = Helpers.round(quantityInConfiguredUnit, 1);
      state.averageProductGiveaway.value = targetValueInConfiguredUnit ? roundedAverageValue : null; // TODO This is a patch. Fix the root cause (Metric-Calculator, class ContextMetricsCalculator, lines 157-165
      state.averageProductGiveaway.target = targetValueInConfiguredUnit;
      state.averageProductGiveaway.unit = configuredUnit;
    },
    setJustificationDelayInSeconds(state, value) {
      state.justificationDelayInSeconds = value;
    },
    setCurrentGraphSelection(state, newGraphSelection) {
      state.graphSelection = newGraphSelection;
    },
    setWorkShiftsCoverage(state, list) {
      function sortCoverageByStartTimeMillisDesc(cov1, cov2) {
        const start1 = cov1.start_time_millis;
        const start2 = cov2.start_time_millis;
        return start1 > start2 ? -1 : start2 > start1 ? 1 : 0;
      }

      list.forEach((ws) => {
        ws["start_time_millis"] = DateTime.fromISO(ws.start_date).toMillis();
        if (ws.end_date) {
          ws["end_time_millis"] = DateTime.fromISO(ws.end_date).toMillis();
        } else {
          ws["end_time_millis"] = null;
        }
      });
      state.workShiftCoverage = list.sort(sortCoverageByStartTimeMillisDesc);
    },
    setProductionRunsCoverage(state, value) {
      state.productionRunCoverage = value;
    },
    setProductionUnitProducts(state, value) {
      state.productionUnitProducts = value;
    },
    setProductionUnitTags(state, value) {
      state.activeProductionUnitTags = value;
    },
  },
};

function getTimelineBlocks(blocks, markers, duration) {
  const nullWorkShiftStarts = markers.filter(
    (m) => m.type === "WorkShiftStart" && m.label === "" && m.work_shift_start_properties?.is_manually_created,
  );
  const nullWorkShiftStartsWithNonOOPOverlappingBlocks = nullWorkShiftStarts.filter((ws) => {
    return !!blocks.find(
      (b) => b.start <= ws.start && b.duration + b.start > ws.start && b.state.toLowerCase() !== "out_of_production",
    );
  });
  const oopBlocks = nullWorkShiftStartsWithNonOOPOverlappingBlocks.map((ws) => {
    const wsIndex = markers.findIndex((m) => m.event_id === ws.event_id);
    if (wsIndex === markers.length - 1) {
      // last marker, oop until the end of the timeline
      return {
        start: ws.start,
        duration: duration - ws.start,
        state: "out_of_production",
        sub_blocks: null,
        downtime: null,
      };
    } else {
      const nextWs = markers[wsIndex + 1];
      return {
        start: ws.start,
        duration: nextWs.start - ws.start,
        state: "out_of_production",
        sub_blocks: null,
        downtime: null,
      };
    }
  });
  const transformedBlocks = blocks
    .map((b) => ({
      start: b.start,
      duration: b.duration,
      state: b.state.toLowerCase(),
      sub_blocks: b.sub_blocks?.map((sb) => ({
        duration: sb.duration,
        state: sb.state.toLowerCase(),
      })),
      downtime: b.downtime,
    }))
    .filter((b) => b.duration > 0);
  transformedBlocks.push(...oopBlocks);
  return transformedBlocks;
}
